<template>
<div class="web-header">
  <v-app-bar
    :color="backgroundColor"
    app
  >
    <boda-hamn-header-content v-if="campingKey === 'bodahamn'"></boda-hamn-header-content>
    <bastad-header-content v-else-if="campingKey === 'bastad'"></bastad-header-content>
    <lunnedet-header-content v-else-if="campingKey === 'lunnedet'"></lunnedet-header-content>
    <ostana-header-content v-else-if="campingKey === 'ostana'"></ostana-header-content>
    <skrea-header-content v-else-if="campingKey === 'skrea'"></skrea-header-content>
    <default-header-content v-else></default-header-content>

    <v-btn icon @click.stop="showSidebar = !showSidebar" class="d-none d-lg-block ml-3 pointer">
      <!-- Inloggning pågår: -->
      <v-avatar v-if="isLoadingAuth" item>
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-avatar>
      <!-- Ej inloggad: -->
      <v-avatar v-else-if="!isLoggedIn" item>
        <img
          :src="require('@/assets/images/co_logo_sm.svg')"
          alt="CampingOnline"
        >
      </v-avatar>
      <!-- Inloggad, har bild: -->
      <v-avatar v-else-if="hasImage" item color="primary">
        <img
          :src="profilePicture"
          alt="CampingOnline"
        >
      </v-avatar>
      <!-- Inloggad, ingen bild: -->
      <v-avatar v-else item :color="user && user.color ? user.color : 'light-green'">
        <span class="white--text mx-auto" v-text="user && user.initials ? user.initials : 'XX'"></span>
      </v-avatar>
    </v-btn>
    <v-app-bar-nav-icon :color="sidebarToggleButtonColor" class="d-lg-none ml-auto sidebar-toggle-button" @click.stop="showSidebar = !showSidebar"></v-app-bar-nav-icon>
  </v-app-bar>

  <web-sidebar
    v-model="showSidebar"
  ></web-sidebar>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import WebSidebar from './WebSidebar.vue'

export default {
  name: 'WebHeader',
  components: {
    WebSidebar,
    // TODO: [CO-2856] vet inte om vi kan komma ifrån att lägga in saker i header, footer och sidebar när en camping vill ha sina egna
    DefaultHeaderContent: () => import(/* webpackChunkName: 'DefaultHeaderContent' */'./DefaultHeaderContent.vue'),
    BodaHamnHeaderContent: () => import(/* webpackChunkName: 'BodaHamnHeaderContent' */'./bodahamn/HeaderContent.vue'),
    BastadHeaderContent: () => import(/* webpackChunkName: 'BastadHeaderContent' */'./bastad/HeaderContent.vue'),
    LunnedetHeaderContent: () => import(/* webpackChunkName: 'LunnedetHeaderContent' */'./lunnedet/HeaderContent.vue'),
    OstanaHeaderContent: () => import(/* webpackChunkName: 'OstanaHeaderContent' */'./ostana/HeaderContent.vue'),
    SkreaHeaderContent: () => import(/* webpackChunkName: 'SkreaHeaderContent' */'./skrea/HeaderContent.vue')
  },
  props: [],
  data: () => ({
    showSidebar: false
  }),
  computed: {
    ...mapState(
      {
        user: state => state.user.user,
        isLoggingIn: state => state.user.isLoggingIn,
        isConnecting: state => state.user.isConnecting,
        campingKey: state => state.campingKey
      }
    ),
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn'
    }),
    isLoadingAuth: function () {
      return this.isLoggingIn || this.isConnecting
    },
    profilePicture: function () {
      return this.user?.photoUrl
    },
    hasImage: function () {
      return this.profilePicture != null
    },
    backgroundColor: function () {
      switch (this.campingKey) {
        case 'bodahamn':
          return '#d3c7b9'
        case 'bastad':
          return '#fff'
        case 'lunnedet':
          return '#555'
        default:
          return 'RGB(245, 245, 245)'
      }
    },
    sidebarToggleButtonColor: function () {
      switch (this.campingKey) {
        case 'lunnedet':
          return 'rgba(255, 255, 255, 0.8)'
        case 'ostana':
          return 'rgba(255, 255, 255, 1)'
        default:
          return ''
      }
    }
  }
}
</script>
