export const domainMap = {
  'boka.bastadcamping.se': 'bastad',
  'boka.bodahamnscamping.se': 'bodahamn',
  'bokabodahamn.campingonline.se': 'bodahamn',
  'boka.democamping.se': 'demo',
  'devbook.campingonline.se': 'bastad'
}

export function getNamespace () {
  const hostname = window.location.hostname
  if (domainMap[hostname]) {
    return domainMap[hostname]
  }

  const pathname = window.location.pathname
  if (pathname === '/') {
    if (process.env.NODE_ENV !== 'production') {
      fetch('https://server.test/web/namespace').then(res => res.json()).then(data => {
        window.location.href = 'http://' + window.location.host + '/' + data.namespace
      })
      return
    }
    window.location.href = 'https://www.campingonline.se/boka'
  }

  const namespace = pathname.split('/')[1]

  console.log('using namespace', namespace)
  return namespace
}

export function isCustomDomain () {
  return !!domainMap[window.location.hostname]
}
