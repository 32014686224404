<template>
<div class="web-footer">
  <v-footer padless>
    <boda-hamn-footer-content v-if="campingKey === 'bodahamn'"></boda-hamn-footer-content>
    <bastad-footer-content v-else-if="campingKey === 'bastad'"></bastad-footer-content>
    <lunnedet-footer-content v-else-if="campingKey === 'lunnedet'"></lunnedet-footer-content>
    <ostana-footer-content v-else-if="campingKey === 'ostana'"></ostana-footer-content>
    <skrea-footer-content v-else-if="campingKey === 'skrea'"></skrea-footer-content>
    <default-footer-content v-else></default-footer-content>
    <v-card
      flat
      tile
      :style="'color: ' + textColor"
      :color="backgroundColor"
    >
      <v-divider></v-divider>
      <v-card-text class="d-flex align-center justify-center eq-width-items">
        <div class="text-right"><a href="https://www.campingonline.se" class="text-decoration-none co-link">CampingOnline</a></div>
        <div class="heart"><v-icon size="20px" dark class="px-3">mdi-heart</v-icon></div>
        <div class="text-left"><span>{{ camping ? camping.name : 'Camping' }}</span></div>
      </v-card-text>
    </v-card>
  </v-footer>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'WebFooter',
  components: {
    // TODO: [CO-2856] vet inte om vi kan komma ifrån att lägga in saker i header, footer och sidebar när en camping vill ha sina egna
    DefaultFooterContent: () => import(/* webpackChunkName: 'DefaultFooterContent' */'./DefaultFooterContent.vue'),
    BodaHamnFooterContent: () => import(/* webpackChunkName: 'BodaHamnFooterContent' */'./bodahamn/FooterContent.vue'),
    BastadFooterContent: () => import(/* webpackChunkName: 'BastadFooterContent' */'./bastad/FooterContent.vue'),
    LunnedetFooterContent: () => import(/* webpackChunkName: 'LunnedetFooterContent' */'./lunnedet/FooterContent.vue'),
    OstanaFooterContent: () => import(/* webpackChunkName: 'OstanaFooterContent' */'./ostana/FooterContent.vue'),
    SkreaFooterContent: () => import(/* webpackChunkName: 'SkreaFooterContent' */'./skrea/FooterContent.vue')
  },
  computed: {
    ...mapState(
      {
        camping: state => state.camping,
        campingKey: state => state.campingKey
      }
    ),
    backgroundColor: function () {
      switch (this.campingKey) {
        case 'bodahamn':
          return '#d3c7b9'
        case 'bastad':
          return '#003462'
        case 'lunnedet':
          return '#555'
        case 'skrea':
          return '#D8BE3A'
        default:
          return 'primary'
      }
    },
    textColor: function () {
      switch (this.campingKey) {
        case 'bodahamn':
          return '#5a5a5a'
        case 'bastad':
          return 'white'
        case 'lunnedet':
          return 'rgba(255, 255, 255, 0.8)'
        default:
          return 'white'
      }
    }
  }
}
</script>
