<template>
<div class="web-sidebar">
  <v-navigation-drawer
    v-model="show"
    right
    fixed
    temporary
    hide-overlay
    class="d-flex flex-column"
  >
    <boda-hamn-sidebar-content v-if="campingKey === 'bodahamn'"></boda-hamn-sidebar-content>
    <bastad-sidebar-content v-else-if="campingKey === 'bastad'"></bastad-sidebar-content>
    <lunnedet-sidebar-content v-else-if="campingKey === 'lunnedet'"></lunnedet-sidebar-content>
    <ostana-sidebar-content v-else-if="campingKey === 'ostana'"></ostana-sidebar-content>
    <skrea-sidebar-content v-else-if="campingKey === 'skrea'"></skrea-sidebar-content>
    <default-sidebar-content v-else></default-sidebar-content>
    <v-divider></v-divider>

    <v-list-item v-if="isLoggedIn" two-line>
      <!-- Ej inloggad: -->
      <v-list-item-avatar v-if="!isLoggedIn" item>
        <img
          :src="require('@/assets/images/co_logo_sm.svg')"
          alt="CampingOnline"
        >
      </v-list-item-avatar>
      <!-- Inloggad, har bild: -->
      <v-list-item-avatar v-else-if="hasImage" item color="primary">
        <img
          :src="profilePicture"
          alt="CampingOnline"
        >
      </v-list-item-avatar>
      <!-- Inloggad, ingen bild: -->
      <v-list-item-avatar v-else item :color="user && user.color ? user.color : 'light-green'">
        <span class="white--text mx-auto" v-text="user && user.initials ? user.initials : '??'"></span>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ userFullName }}</v-list-item-title>
        <v-list-item-subtitle>{{ $t('sidebar.logged_in') }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-else>
      <v-list-item-content>
        <img
          :src="require('@/assets/images/co_logo_lg.svg')"
          alt="CampingOnline"
          width="100%"
          class="pa-2"
        >
      </v-list-item-content>
    </v-list-item>

    <v-list v-if="isLoggedIn">
      <v-list-item link :to="{ name: 'Index' }" exact>
        <v-list-item-icon>
          <v-icon>mdi-magnify</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('sidebar.book_camping') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-list-item link :to="{ name: 'BookingsPage' }">
        <v-list-item-icon>
          <v-icon>mdi-file-document-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('sidebar.my_bookings') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
    </v-list>
    <v-list v-else>
      <v-list-item-content class="pa-2">
        <v-btn color="success" :to="{ name: 'LoginPage' }">{{ $t('logincard.log_in') }}</v-btn>
      </v-list-item-content>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-btn class="mt-auto" block text color="info" v-on:click="showTerms">{{ $t('sidebar.terms')  }}</v-btn>
      </div>
      <div v-if="isLoggedIn" class="pa-2">
        <v-btn class="mt-auto" block outlined color="error" :loading="isLoggingOut" v-on:click="logout">{{ $t('sidebar.logout')  }}</v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</div>
</template>

<style lang="scss">

</style>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'WebSidebar',
  components: {
    DefaultSidebarContent: () => import(/* webpackChunkName: 'DefaultSidebarContent' */'./DefaultSidebarContent.vue'),
    BodaHamnSidebarContent: () => import(/* webpackChunkName: 'BodaHamnSidebarContent' */'./bodahamn/SidebarContent.vue'),
    BastadSidebarContent: () => import(/* webpackChunkName: 'BastadSidebarContent' */'./bastad/SidebarContent.vue'),
    LunnedetSidebarContent: () => import(/* webpackChunkName: 'LunnedetSidebarContent' */'./lunnedet/SidebarContent.vue'),
    OstanaSidebarContent: () => import(/* webpackChunkName: 'OstanaSidebarContent' */'./ostana/SidebarContent.vue'),
    SkreaSidebarContent: () => import(/* webpackChunkName: 'SkreaSidebarContent' */'./skrea/SidebarContent.vue')
  },
  props: {
    value: Boolean
  },
  data: () => ({
    headerPages: []
  }),
  computed: {
    ...mapState(
      {
        user: state => state.user.user,
        isLoggingIn: state => state.user.isLoggingIn,
        isLoggingOut: state => state.user.isLoggingOut,
        isConnecting: state => state.user.isConnecting,
        camping: state => state.camping,
        isFetchingCamping: state => state.isFetchingCamping,
        campingKey: state => state.campingKey
      }
    ),
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn'
    }),
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    isLoadingAuth: function () {
      return this.isLoggingIn || this.isConnecting
    },
    profilePicture: function () {
      return this.user.photoUrl
    },
    hasImage: function () {
      return this.profilePicture != null
    },
    userFullName: function () {
      if (this.user) {
        return `${this.user.given_name} ${this.user.surname}`
      }
      return ''
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('user/logout')
    },
    showTerms: function () {
      this.$store.commit('SET_SHOW_TERMS_DIALOG', true)
    }
  }
}
</script>
