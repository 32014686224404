import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import API from '@/services/API.js'
import * as search from '@/store/modules/search.js'
import * as country from '@/store/modules/country.js'
import * as user from '@/store/modules/user.js'
import * as booking from '@/store/modules/booking.js'
import * as availability from '@/store/modules/availability.js'
import * as checkin from '@/store/modules/checkin.js'
import * as checkout from '@/store/modules/checkout.js'
import { getNamespace, isCustomDomain } from '@/services/domainMap.js'

Vue.use(Vuex)
const campingName = getNamespace()
const customDomain = isCustomDomain()

// TODO: snakecase på localstorage keys
let campingCollection = {}
if (localStorage.getItem('currentCamping') === null) {
  localStorage.currentCamping = campingName
} else if (localStorage.currentCamping !== campingName) {
  if (localStorage.getItem('campingCollection') === null) {
    localStorage.campingCollection = JSON.stringify(campingCollection)
  } else {
    campingCollection = JSON.parse(localStorage.campingCollection)
  }
  if (campingCollection[localStorage.currentCamping] === undefined) {
    campingCollection[localStorage.currentCamping] = {}
  }
  for (let i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i) !== 'campingCollection') {
      campingCollection[localStorage.currentCamping][localStorage.key(i)] = localStorage.getItem(localStorage.key(i))
    }
  }
  localStorage.clear()
  localStorage.campingCollection = JSON.stringify(campingCollection)
  if (campingCollection[campingName] !== undefined) {
    const campingData = campingCollection[campingName]
    for (const key in campingData) {
      if (key !== 'campingCollection') {
        localStorage[key] = campingData[key]
      }
    }
  }
  localStorage.currentCamping = campingName
}

const localStateBooking = localStorage.getItem('booking')
const localStateApiToken = localStorage.getItem('api_token')

export default new Vuex.Store({
  modules: {
    search,
    country,
    user,
    booking,
    availability,
    checkin,
    checkout
  },
  state: {
    isInitialised: false,
    now: null,
    isFetchingCamping: false,
    isSelfCheckinPage: false,
    camping: {},
    showTermsDialog: false,
    showCampingTermsDialog: false,
    previousSearchUrl: '/',
    categories: [],
    currentCategory: null,
    isFetchingCategories: false,
    currentPageName: '',
    pagesRequiringBooking: ['ReviewPage', 'PaymentPage', 'RegisterPage', 'AddonsPage'],
    pagesRequiringReservation: ['ReviewPage', 'PaymentPage', 'AddonsPage'],
    headerPages: [],
    campingKey: campingName,
    isCustomDomain: customDomain
  },
  mutations: {
    SET_CATEGORIES (state, categories) {
      state.categories = categories
    },
    SET_CURRENT_CATEGORY (state, categoryName) {
      state.currentCategory = state.categories.find(cat => cat.name.toLowerCase() === categoryName)
      this.dispatch('availability/fetchAvailability')
    },
    SET_CURRENT_PAGE_NAME (state, val) {
      state.currentPageName = val
    },
    SET_SHOW_TERMS_DIALOG (state, val) {
      state.showTermsDialog = val
    },
    SET_SHOW_CAMPING_TERMS_DIALOG (state, val) {
      state.showCampingTermsDialog = val
    },
    SET_CAMPING (state, val) {
      state.camping = Object.assign({}, val)
    },
    SET_HEADER_PAGES (state, val) {
      state.headerPages = val
    },
    SET_IS_FETCHING_CAMPING (state, val) {
      state.isFetchingCamping = val
    },
    SET_IS_SELF_CHECKIN_PAGE (state, val) {
      state.isSelfCheckinPage = val
    },
    SET_IS_INITIALISED (state, val) {
      state.isInitialised = val
    }
  },
  actions: {
    initialiseStore ({ commit, dispatch }) {
      Promise.allSettled([
        dispatch('fetchCamping'),
        dispatch('fetchCategories'),
        dispatch('user/initialise', localStateApiToken),
        dispatch('booking/initialise', localStateBooking)
      ])
        .then(() => {
          commit('SET_IS_INITIALISED', true)
        })
    },
    navigating ({ commit, dispatch }, route) {
      const isSelfCheckin = !!route.to.meta.isSelfCheckin
      commit('SET_IS_SELF_CHECKIN_PAGE', isSelfCheckin)
      if (isSelfCheckin) {
        dispatch('checkin/initialise', { force: false })
      }
      if (route.to.name === 'SearchPage' || route.to.name === 'Index') {
        dispatch('search/navigatingToSearchPage', route)
      } else if (route.to.name === 'CategoryPage') {
        const categoryName = route.to.params.category.toLowerCase()
        commit('SET_CURRENT_CATEGORY', categoryName)
        dispatch('booking/navigatingToCategoryPage', route)
      }
    },
    navigated ({ commit, dispatch }, route) {
      commit('SET_CURRENT_PAGE_NAME', route.to.name)
      if (route.to.name === 'SearchPage' || route.to.name === 'Index') {
        dispatch('search/navigatedToSearchPage', route)
      } else if (route.to.name === 'CategoryPage') {
        const categoryName = route.to.params.category.toLowerCase()
        commit('SET_CURRENT_CATEGORY', categoryName)
      }
    },
    refreshNow ({ state }) {
      state.now = window.dayjs()
    },
    fetchCategories ({ commit, state, rootState }) {
      if (!state.isFetchingCategories && state.categories.length === 0) {
        state.isFetchingCategories = true
        API.httpClient.get('categories')
          .then(({ data }) => {
            commit('SET_CATEGORIES', data)
            let categoryName
            if (router.currentRoute.name === 'CategoryPage') {
              categoryName = router.currentRoute.params.category.toLowerCase()
              commit('SET_CURRENT_CATEGORY', categoryName)
            } else {
              if (rootState.booking.position) {
                categoryName = rootState.booking.position.category.name.toLowerCase()
                commit('SET_CURRENT_CATEGORY', categoryName)
              }
            }
            this.dispatch('availability/fetchAvailability')
            this.dispatch('availability/fetchPrices')
            this.dispatch('booking/fetchCharges')
          })
          .finally(() => {
            state.isFetchingCategories = false
          })
      }
    },
    fetchCamping ({ commit, rootState }) {
      commit('SET_IS_FETCHING_CAMPING', true)
      API.httpClient.get('header').then(({ data }) => {
        commit('SET_HEADER_PAGES', data.pages)
        data.camping.booking_closed_until = data.camping.booking_closed_until !== null ? window.dayjs(data.camping.booking_closed_until) : null
        data.camping.first_available_booking_date = window.dayjs(data.camping.first_available_booking_date)
        commit('SET_CAMPING', data.camping)

        let dateIn = data.camping.first_available_booking_date.startOf('date')
        if (dateIn.isBefore(window.dayjs().startOf('date'))) {
          dateIn = window.dayjs().startOf('date')
        }
        const dateOut = dateIn.add(1, 'day').startOf('date')
        const checkInDate = {
          date: dateIn.clone(),
          readable: dateIn.format('ddd D MMM'),
          param: dateIn.format('YYYY-MM-DD')
        }
        const checkOutDate = {
          date: dateOut.clone(),
          readable: dateOut.format('ddd D MMM'),
          param: dateOut.format('YYYY-MM-DD')
        }

        if (rootState.search.checkInDate.date === null || rootState.search.checkOutDate.date === null) {
          rootState.search.checkInDate = checkInDate
          rootState.search.checkOutDate = checkOutDate
          this.dispatch('search/validateDates')
          this.dispatch('availability/fetchPrices')
        }
        if (rootState.booking.checkInDate.date === null || rootState.booking.checkOutDate.date === null) {
          rootState.booking.checkInDate = checkInDate
          rootState.booking.checkOutDate = checkOutDate
          this.dispatch('booking/validateDates')
          this.dispatch('booking/fetchCharges')
        }
        this.dispatch('availability/fetchAvailability')

        if (router.currentRoute.params.filter) {
          if (rootState.camping && rootState.camping.lodging_types) {
            const type = rootState.camping.lodging_types.find(e => e.text.toLowerCase() === router.currentRoute.params.filter.toLowerCase())
            if (type) {
              commit('search/SET_FILTER', type, { root: true })
            }
          }
        }
      }).catch(error => {
        console.log('header data error response:', error, error.response)
      })
        .finally(() => {
          commit('SET_IS_FETCHING_CAMPING', false)
        })
    },
    setPreviousSearchUrl ({ state }, val) {
      state.previousSearchUrl = val
    },
    sendFeedback (context, feedback) {
      return API.httpClient.post('feedback', {
        booking_id: feedback.booking_id,
        rating: feedback.rating,
        comments: feedback.comments
      })
    }
  },
  getters: {

  }
})
